import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [

    {
      imageName: "team-2",
      desc: `  Very pleasant approach and quick problem solving is one of many positives Jakub has. He may sometimes be too quick, but if you tell him he will adjust his workflow to fit your needs.`,
      reviewerName: "Pavel Vrtík - CEO Consilia s.r.o.",
      rating: "4.8",
    },
    {
      imageName: "team-4",
      desc: `  Jakub has a professional approach to work, he is detail oriented and efficient. He has great potential to gain more experience,because he learns very quickly. His communication and social skills are also very high which is usually underrated.`,
      reviewerName: "Martin Tesař - CEO Aton Benu Consult s.r.o.",
      rating: "5.0",
    }, 
    {
      imageName: "team-1",
      desc: `  The overall impression was positive, especially for person who should not be very experienced in the field. Great work commitment and surprisingly fast ability to adapt to change.`,
      reviewerName: "Tomáš Hlavatý - Novanta Inc.",
      rating: "4.5",
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div className="testmonial-box media" key={i}>
            <div className="avatar">
              <img
                src={`img/testimonial/${val.imageName}.jpg`}
                alt="review comments"
              ></img>
            </div>
            {/* End avatar */}
            <div className="testmonial-text media-body">
              <p>{val.desc}</p>
              <div className="testmonial-info">
                <h6>{val.reviewerName}</h6>
                <div className="rating-value">
                  <i className="ti-star"></i>
                  <span>{val.rating}</span>
                </div>
              </div>
            </div>
            {/* End testimonial-text */}
          </div>
        ))}
      </Slider>
    </>
  );
}
