import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderNda";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from "../../components/useDocumentTitle";

const HomeSix = () => {
    useDocumentTitle("Jakub Hanuška - NDA");
    return (
        <div className="home-dark">
            <Header />
            {/* End Header Section */}
            <Slider />
            {/* End Banner Section */}
            <section id="about" className="section about-section after-left-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 my-3">
                            <div className="img-box dark-img-box">
                                <img src="img/nda/nda.png" alt="Nda" />
                            </div>
                        </div>

                        <div className="col-lg-6 my-3">
                            <div className="typo-box about-me">
                                <h1><b>STILL UNDER NDA</b></h1>
                                <p>
                                    This work is still covered by an NDA. Once it expires, this work will be
                                    made available on the Portfolio link.
                                </p>



                            </div>
                            {/* End .about me */}
                        </div>
                        {/* End .col */}
                    </div>
                </div>
            </section>
            {/* Footer */}
            <footer className="footer white">
                <div className="container">
                    <Footer />
                </div>
            </footer>
            {/*  End Footer */}
        </div>
    );
};

export default HomeSix;
