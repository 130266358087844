import React from "react";
import Social from "../Social";
import { videoTagString } from "react-video-tag";
videoTagString({
    src: "https://www.ibthemespro.com/resource/krozo/resources/video-2.mp4",
    poster: "img/bg-2.jpg",
});

const Slider = () => {
    return (
        <>
            <section
                id="home"
                className="home-banner overlay overlay-75 inner-content-static video-banner video-banner-dark"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + "./img/nda/nda.png"
                        })`,
                }}
            >
                <div className="container">
                    <div className="row align-items-center full-screen">
                        <div className="col-lg-12">
                            <div className="hb-typo text-center">
                                <h1 className="font-alt">STILL UNDER NDA</h1>

                                <Social />
                                {/* End social slide  */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End .container */}
                <div className="go-to go-to-next">
                    <a href="#about">
                        <span></span>
                    </a>
                </div>
                {/* End bottom scroll */}
            </section>
            {/* End Home Banner  */}
        </>
    );
};

export default Slider;
