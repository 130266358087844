import React from "react";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 my-3">
          <div className="img-box dark-img-box">
            <img src="img/about/about-6.jpg" alt="smiling a girl" />
          </div>
        </div>

        <div className="col-lg-6 my-3">
          <div className="typo-box about-me">
            <h3>Jakub Hanuška</h3>
            <h5>
              <span className="color-theme">Software</span> developer
              currently based in <span className="color-theme">Sankt Gallen</span>
            </h5>
            <p>
              I am a software engineer with several years of experience, now transitioning into data science with a focus on machine learning. 
              I bring strong problem-solving skills, an analytical mindset, and a practical approach to delivering results. 
              Currently working while studying (part-time) data science in Zurich, 
              I’m passionate about leveraging data to solve complex challenges and eager to 
              contribute in data-driven environments to help organizations achieve their goals.
            </p>
            <div className="row about-list">
              <div className="col-md-6">
                <div className="media">
                  <label>Birthday</label>
                  <p>29th March</p>
                </div>
                <div className="media">
                  <label>Age</label>
                  <p>23 Yr</p>
                </div>
                <div className="media">
                  <label>City</label>
                  <p>St. Gallen</p>
                </div>
                <div className="media">
                  <label>State</label>
                  <p>CH</p>
                </div>
                <div className="media">
                  <label>Nationality</label>
                  <p>CZ</p>
                </div>
              </div>
              {/* End .col */}

              <div className="col-md-6">
                <div className="media">
                  <label>E-mail</label>
                  <p>business@jakubhanuska.cz</p>
                </div>
                <div className="media">
                  <label>Phone</label>
                  <p>CZ: +420 731150050</p>
                  <p>CH: +41 774495121</p>
                </div>
                <div className="media">
                  <label>Discord</label>
                  <p>Rodrigoss#4223</p>
                </div>
                <div className="media">
                  <label>Work</label>
                  <p>Available</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
            <div className="btn-bar">
              <a className="px-btn px-btn-theme" href="img/resume.pdf" download>
                Download CV
              </a>
            </div>
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
