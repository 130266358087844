import React from "react";

const ServiceContent = [
  {
    icon: "ti-desktop",
    title: "Web Development",
    descriptions: `Leveraging cutting-edge technologies like Node.js and Angular, I develop engaging, high-performance web applications that enhance user experience and increase business value. Whether it's upgrading legacy systems or crafting new platforms, I deliver scalable web solutions.`,
  },
  {
    icon: "ti-check-box",
    title: "Quality Assurance",
    descriptions: `I provide comprehensive testing services to ensure software reliability and performance. Utilizing automated and manual testing techniques, I help identify and resolve issues before deployment, ensuring your application operates flawlessly.`,
  },
  {
    icon: "ti-stats-up",
    title: "Machine Learning",
    descriptions: `Specializing in computer vision tasks, I employ PyTorch to develop systems that can analyze and interpret visual information from the world around us. From facial recognition to automated image tagging, these applications serve a variety of industries including security, retail, and healthcare.`,
  },
  {
    icon: "ti-support",
    title: "External Support",
    descriptions: `I offer external IT support to bolster your company's technical capabilities. From troubleshooting complex issues to providing ongoing maintenance, my services ensure your IT infrastructure is robust and efficient.`,
  },
  {
    icon: "ti-cloud-up",
    title: "Cloud Solutions",
    descriptions: `I design and implement cloud architecture solutions, providing scalable, secure, and cost-efficient infrastructure management. My services include everything from migration and deployment to continuous management, using platforms such as AWS, Google Cloud, and Azure.`,
  }
];


export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <div className="feature-box-01">
              <div className="icon">
                <i className={val.icon}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
